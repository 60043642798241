<template>
  <b-row class="container-results-proposals m-0 mx-auto">
    <b-col 
      class="mb-2 col-12 p-0"
      v-if="show_banner">
      <b-carousel
        fade
        id="carousel-1"
        :interval="4000"
        :controls="banners.length > 1"
        :indicators="banners.length > 1"
      >
        <b-carousel-slide
          v-for="(slide, index) in banners"
          :key="index"
          >
        <template #img>
          <img 
            class="component-banner"
            :src="slide.url_image ? slide.url_image : getAssetUrl(slide.image)"
          >
        </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
    <b-col 
      class="mb-2 col-12 p-0" 
      v-if="!show_banner && has_a_plan && ((total_proposals === 0 && !loader_results) || ((Object.keys($route.query).length === 0) && (Object.keys($route.query).length === 0 && networks.length === 0)))">
      <component :is="vue_horizontal" class="horizontal" :displacement="0.3" snap="center">
        <collection-card-network 
        v-for="(i, index) in network" :key="index"
        :data="i"
          :index="index + 1"
          @reload_service="reloadServiceCollectionsNetworks"
          :img="imgNetwork(i)"
          class="component-collection-network"
          />
      </component>
    </b-col>
    <b-col class="filters-fixed-nav mb-1 col-12" ref="filters_fixed" v-if="has_a_plan">
      <div class="row max-width-1400 m-0 ">
        <div class="col-12 col-md-3 col-lg-2 p-0 mb-1 mb-md-0 d-flex justify-content-between border-right pr-1">
          <b-button 
            variant="button-filters" 
            pill 
            :class="`button-filters position-relative ${filters_used_number > 0 ? 'justify-content-between' : 'justify-content-center'}`" 
            @click="open_modal = true; update_modal = !update_modal;">
            <div>
              <b-icon icon="sliders"></b-icon> 
              {{ $t('creator.filters') }}
            </div>
            <div class="badge-number-filters-used" v-if="filters_used_number > 0">{{filters_used_number}}</div>
          </b-button>
          <b-button pill class="button-filters button-trash-icon" variant="button-filters button-trash-icon" @click="clearFilters(true)">
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </div>
        <component :is="vue_horizontal" responsive class="horizontal col-12 col-md-9 col-lg-10 d-flex align-items-center p-0 pl-1" :displacement="0.3" snap="center" v-if="!loader_results">
          <b-badge 
            variant="outline-secondary-light" 
            :class="`outline-secondary-light ${isActiveClass('networks', net)}`" 
            v-for="(net, index) in network" :key="index"
            @click="addNetworkFilter(net)"
          >
            {{nameNetworks(net)}}
          </b-badge>
        </component>
      </div>
    </b-col>
    <b-col class="min-height-500 p-0 w-100">
      <div class="col-12 mb-2 p-0" v-if="(total_proposals > 0 && !loader_results) || (is_free && total_proposals === 0)">
        <strong class="w-100 h3 d-block">{{(networks.length > 0) ? getNetworksCapitalize : $t('creator.allProposals')}} </strong>
        <span class="h5" v-if="$route.query.query">
          {{$t('creator.searchBy')}} "{{$route.query.query}}"
        </span>
      </div>
      <div class="w-100" v-if="!loader_results">
        <div class="grid-search-proposal w-100 position-relative" :key="loader_results">
          <div v-for="item in proposals" :key="item.uuid">
            <card-category
              :proposal="item"
              :countries="countries"
              @remove_fixed_nav="removeFixedNavFilters"
              :width="''"
            />
          </div>
          <div :class="`${isCreatorOrBrandWithPlan ? 'color-card-premium' : 'color-card-free'} update-membership-card-category`" v-if="(current_page === Math.ceil(total_proposals / per_page)) || (total_proposals === 0)">
            <p class="text-white">{{ userStatus.text }}</p>
            <b-button variant="light" @click="redirectTo()">{{ userStatus.buttonText }}</b-button>
          </div>
        </div>
        <pagination-brandme 
          class="col-12 d-flex justify-content-center mt-2"
          :total_rows="total_proposals"
          :per_page="per_page"
          :current_page="current_page"
          @update_current_page="updateCurrentPage"
          :key="current_page"
          v-if="total_proposals > 0"
        ></pagination-brandme>
      </div>
      <div class="w-100" v-else>
        <loader-video-brandme :text_loading="'creator.loading'" :position_class="'position-relative'"></loader-video-brandme>
      </div>
      <b-col class="empty-state-container-proposals col-12 mt-2" v-if="(total_proposals === 0 && !!getUserData()) && !loader_results">
        <no-Results 
          :title="`${$t('creator.noResults')} ${$route.query.query ? $route.query.query : ''}`"
          :subtitle="$t('creator.textFilters')"
          :text_button="$t('creator.search')"
          :btn_action="clearFilters"
          :show_icon="false"
        />
      </b-col>
    </b-col>
    <b-col class="col-12 p-0 pt-1" v-if="role !== 'creator'">
      <card-casting-call
        :disabled_btn="false"
        :colorCard="'color-card-custom'"
        :btn_action="redirectCreateCasting"
        :textInfo="$t('creator.chooseChampion')"
        :subtitle="$t('creator.directRelation')"
        :textButton="avaible_plan ? $t('dashboard.create_casting_call') : $t('creator.upgrade_to_create_castings')"
        :img="getUrlCategory('home_casting_call')"
        :can_create_casting="avaible_plan"
      />
    </b-col>

    <filter-search-proposal 
      :open_modal="open_modal" 
      :key="update_modal"
      :filters_layout="filters_layout"
      :countries_json="countries"
      @filter="filterAction"
    ></filter-search-proposal>
    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
  </b-row>
</template>

<script>
import {
  BCarouselSlide,
  BCarousel,
  BImg,
  BRow,
  BCol,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { getUrlCategory, getAssetUrl } from '@/libs/utils/urls'
import { nameNetworks } from '@/libs/utils/formats'
import { getUserData } from '@/libs/utils/user'
import { getCountries } from '@/libs/utils/others';

export default {
  name: 'searchProposal',
  components: {
    BCarouselSlide,
    BCarousel,
    BImg,
    BRow,
    BCol,
    BButton,
    BBadge,
    cardCategory: () => import('@/views/pages/proposals/cardCategory.vue'),
    collectionCardNetwork: () => import('@/views/components/proposals/collectionCardNetwork.vue'),
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    cardCastingCall: () => import('@/views/components/proposals/cardCastingCall.vue'),
    filterSearchProposal: () => import('@/views/components/proposals/filterSearchProposal.vue'),
    paginationBrandme: () => import('@/views/components/pagination/PaginationBrandme.vue'),
    LoaderVideoBrandme: () => import('@/views/components/banner/LoaderVideoBrandme.vue'),
    ModalLogin: () => import('@/views/components/modal/ModalLogin.vue'),
  },
  data() {
    return {
      open_modal_login: false,
      update_modal_login: false,
      countries: getCountries(),
      banners: [],
      show_banner: false,
      getAssetUrl,
      total_proposals: 0,
      getUrlCategory,
      nameNetworks,
      getUserData,
      avaible_plan: null,
      proposals: [],
      collection_card_networks: [],
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      current_page: 1,
      filters_used_number: 0,
      per_page: 15,
      networks: [],
      query: undefined,
      open_modal: false,
      update_modal: false,
      loader_results: false,
      filters_layout: [],
      filters: {},
      changFilters: false,
      role: '',
      filter_button_selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
      vue_horizontal: null,
    }
  },
  computed: {
    getNetworksCapitalize() {
      let nets = this.networks.slice();
      nets = nets.map((n) => nameNetworks(n));
      return nets.join(', ');
    },
    is_free() {
      return !getUserData() || (getUserData() && getUserData().current_plan.name === 'Free')
    },
    has_a_plan() {
      return (getUserData() && getUserData().current_plan.name !== 'Free')
    },
    isCreatorOrBrandWithPlan() {
      const { isCreator, isBrand, hasPlan } = this.getUserStatusData();
      return isCreator || (isBrand && hasPlan);
    },
    userStatus() {
      const { isCreator, hasPlan, isBrand } = this.getUserStatusData();

      return {
        isCreator,
        hasPlan,
        isBrand,
        text: isCreator ? this.$t('creator.textCardProposalsCreator') : (isBrand && hasPlan) ? this.$t('creator.textCardPremium') : this.$t('creator.updateMembershipToSee'),
        buttonText: isCreator ? this.$t('creator.btnCardProposals') : (isBrand && hasPlan) ? this.$t('creator.goSearch') : this.$t('creator.updateMembership'),
      };
    },
  },
  created() {
    this.setPageToRouteVisited();
    const user = getUserData()
    if (user) this.avaible_plan = user.current_plan.create_casting_call

    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "white";
    this.$root.$on('update_proposals', (selected_button) => {
      if (selected_button) this.filter_button_selected = selected_button;
      this.addFiltersFromQuery();
      this.getProposals();
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
    });
    this.addFiltersFromQuery();
    this.getProposals();
    const banner = this.getQueryParam('banner');
    if (banner) {
      this.collaborationBanners(banner);
    }
    
    setTimeout(() => {
      this.$nextTick(() => {
        const filters_fixed_nav = document.getElementsByClassName('filters-fixed-nav')
        if (filters_fixed_nav.length > 0) filters_fixed_nav[0].style.display = 'block'
      })
    }, 500)
    const userData = getUserData();
  
    if (userData && userData.groups && userData.groups.length > 0) {
      this.role = userData.groups[0].name;
    }
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default;
  },
  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    body.removeAttribute('style');
  },
  methods: {
    getUserStatusData() {
      const userData = getUserData();
      const isCreator = userData && userData.main_group.name === 'creator';
      const hasPlan = userData && userData.current_plan.name !== 'Free';
      const isBrand = userData && userData.main_group.name !== 'creator';

      return {
        isCreator,
        hasPlan,
        isBrand,
        userData
      };
    },
    redirectTo() {
      const { isCreator, isBrand, hasPlan } = this.getUserStatusData();

      if (isCreator) {
        window.open('https://brandme.la/membresias/', '_blank', 'noopener,noreferrer');
      } else if (isBrand && hasPlan) {
        this.$router.push({ name: 'auth-search' });
      } else if (!isBrand && !hasPlan) {
        this.open_modal_login = true;
        this.update_modal_login = !this.update_modal_login;
      } else if (isBrand && !hasPlan) {
        this.$router.push({ name: 'pages-account-setting', params: { section: 'membership' } });
      }
    },
    collaborationBanners(banner) {
      proposals_services.collaborationBanners({ banner }).then((response) => {
        this.banners = response.results;
        this.show_banner = true
      });
    },
    getQueryParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },
    setPageToRouteVisited() {
      if (localStorage.getItem('routes_visited')) {
        const array_routes = JSON.parse(localStorage.getItem('routes_visited'));

        const route_before_last = array_routes[array_routes.length - 2];
        if (route_before_last?.name === 'view_proposal') {
          const current_page_setted = array_routes[array_routes.length - 3].current_page;
          this.current_page = current_page_setted
        }
        
        array_routes[array_routes.length - 1].current_page = this.current_page;
        localStorage.setItem('routes_visited', JSON.stringify(array_routes));
      }
      return null;
    },
    updateCurrentPage(new_current_page) {
      this.current_page = new_current_page;
      this.getProposals();

      const array_routes = JSON.parse(localStorage.getItem('routes_visited'));
      if (array_routes) {
        array_routes[array_routes.length - 1].current_page = this.current_page;
        localStorage.setItem('routes_visited', JSON.stringify(array_routes));
      }
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

    },
    redirectCreateCasting() {
      if (this.avaible_plan) this.$router.push({ name: 'steps_casting_call', params: {step: 'select-service'}})
      else this.$router.push({name: 'pages-account-setting', params: { section: 'membership'}})
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    addFiltersFromQuery() {
      const query = {};
      this.networks = [];
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.network) {
          this.networks.push(this.$route.query.network);
          this.filters = query;
        } else if (this.$route.query.work_types) {
          if (Object.keys(this.filters).length === 0) this.filters.work_types = [this.$route.query.work_types];
          else this.filters.work_types.push(this.$route.query.work_types);
          this.filters_used_number = 1;
        } else if (this.$route.query.query) {
          query.query = this.$route.query.query;
          this.filters = query;
        } if (this.$route.query.banner) {
          query.business_name = this.$route.query.banner;
          this.filters = query;
        } else if (this.$route.query.content_type) {
          if (Object.keys(this.filters).length === 0) this.filters.content_types = [this.$route.query.content_type];
          else this.filters.content_types.push(this.$route.query.content_type);
          this.filters_used_number = 1;
        }
      } 
    },
    reloadServiceCollectionsNetworks(filters) {
      this.clearFilters(false);
      this.networks.push(filters.networks[0]);
      this.filters = filters;
      this.getProposals();
    },
    removeFixedNavFilters() {
      if (this.has_a_plan) document.getElementsByClassName('filters-fixed-nav')[0].style.display = 'none';
    },
    clearFilters(update = true) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({name: 'search_proposal'});
      }
      this.filter_button_selected.selected = false;
      this.filter_button_selected.type_button = '';
      this.filter_button_selected.list_button = []
      this.filters_layout = [];
      this.filters = {};
      this.networks = [];
      this.filters_used_number = 0;
      this.$root.$emit('update_search_query', '');
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
      if (update) this.getProposals();
    },
    filterAction(filters, filters_used_number, filters_layout) {
      this.open_modal = false;
      this.update_modal = !this.update_modal;
      this.filters_used_number = filters_used_number;
      this.filters_layout = filters_layout;
      this.filters = filters;
      this.getProposals();
    },

    getProposals() {
      this.loader_results = true;
      const query = {...this.filters}; 
      query.networks = this.networks
      query.query = this.$route.query.query ? this.$route.query.query : undefined;
      const user = getUserData()
      proposals_services.searchProposals(query, this.per_page, this.current_page, !!user).then((response) => {
        this.total_proposals = response.count
        this.proposals = response.results;
        this.changFilters = !this.changFilters
        this.loader_results = false;
      });      
    },
    isActiveClass(name, value, filter = false) {
      return this[name].find((item) => item === value) !== undefined ? filter ? 'active-button-filters' : 'active-button-network' : '';
    },
    addNetworkFilter(net) {
      this.networks.find((network) => network === net) === undefined ? this.networks.push(net) : this.networks = this.networks.filter((item) => item !== net);
      this.getProposals();
    },
  },
}
</script>

<style>
.color-card-premium {
  background: linear-gradient(225deg,#068eb5 0%,#9b51e0 47%);
}
.color-card-free {
  background: linear-gradient(#9146ff,#fa1ed2,#ea7078);
}
.color-card-custom {
  background-image: linear-gradient(rgb(145, 70, 255), rgb(250, 30, 210), rgb(234, 112, 120));
}
</style>
<style scoped>
.update-membership-card-category {
  aspect-ratio:  265 / 390;
  cursor: pointer;
  border-radius: 1em;
  box-shadow: none !important;
  overflow: hidden;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aspect-ratio {
  aspect-ratio: 1 / 1;
}
.grid-search-proposal {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));;
  grid-gap: 2rem;
  grid-auto-flow: dense;
}
.min-height-500 {
  min-height: 80vh;
}
.empty-state-container-proposals {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.max-width-1400 {
  max-width: 1440px;
  margin: 0px auto;
}
.filters-fixed-nav {
  background-color: white;
  position: sticky;
  width: 100%;
  left: 0;
  top: 10.5em;
  z-index: 50;
  box-shadow: 0px 10px 5px white;
  /* padding: 0px !important; */
  padding: 1em 0 1em 0;
  animation: filters-fixed 600ms;
}
.button-filters {
  border: 1px solid #ced4da !important;
  display: flex;
  align-items: center;
  width: 130px !important;
}
.button-trash-icon {
  display: block !important;
  width: 60px !important;
}
.button-filters:hover {
  background-color: #e8e8e8 !important;
}
.badge-number-filters-used {
  background-color: #7367F0;
  width: 20px;
  height: 20px;
  border-radius: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 1em;
}
.outline-secondary-light {
  width: 100px !important;
  height: 41px;
  border: 1px solid #ced4da !important;
  border-radius: 5em;
  color: #6c757d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1em 5em;
}
.outline-secondary-light:hover {
  background-color: #e8e8e8 !important;
  transition: all 300ms;
}
.outline-secondary-light > .btn {
  padding: 0.2em !important;
}
.active-button-network, .active-button-network:hover {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.active-button-filters {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.card-nav-search {
  display: flex !important; 
}
.component-collection-network {
  width: 660px !important;
}
.component-banner {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.container-results-proposals {
  max-width: 1440px;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0;
  margin: 0;
  padding: 8px;
  height: 100%;
  box-shadow: none;
  background: none;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white);
  padding-right: 24px;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white);
  padding-left: 24px;
}

.horizontal >>> .v-hl-btn {
  top: 0;
  bottom: 0;
  transform: translateX(0);
}
.container-search-filters {
  width: 550px;
}
.container-search-filters .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 40px;
  width: 100%;
  height: 41px;
}
.float-suggestions-search {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 4em;
  z-index: 100;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.badge-float-suggestions {
  background-color: #f1f1f1 ;
  color: #444;
  cursor: pointer;
}
.img-network-badge {
  width: 18px;
  height: 18px;
}
.container-search-filters > .icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  font-size: 25px;
  top: 0.3em;
  left: 0.3em;
  cursor: pointer;
}
.p-07 {
  padding: 0.7em;
}
@media(max-width: 1200px) {
  .grid-search-proposal {
    grid-template-columns: repeat(4, minmax(100px, 1fr));;
  }
  .filters-fixed-nav {
    top: 5em;
  }
}
@media(max-width: 1000px) {
  .grid-search-proposal {
    grid-template-columns: repeat(3, minmax(100px, 1fr));;
  }
}
@media(max-width: 850px) {
  .component-banner {
    object-fit: cover;
  }
}
@media(max-width: 700px) {
  .grid-search-proposal {
    grid-template-columns: repeat(2, minmax(100px, 1fr));;
  }
}
@media(max-width: 450px) {
  .grid-search-proposal {
    grid-template-columns: repeat(1, 1fr);;
  }
}
@media(min-width: 1200px) {
  .col-25 {
    max-width: 20% !important;
  }
}
@keyframes filters-fixed {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>